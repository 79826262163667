<template>
  <div class="fill-height">
    <IproMain
        jenis="IPRO"
    ></IproMain>
  </div>
</template>

<script>
import IproMain from "../../components/public_potensi_kawasan/PotensiMain";

export default {
  name: "IniJatimView",
  components:{
    IproMain
  },
  mounted() {
    this.$store.dispatch("potensi/updateShowMenuBar", false)
  }
}
</script>

<style scoped>

</style>